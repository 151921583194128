/* src/index.css */
:root {
    /* Font Families */
    --font-family-primary: -apple-system, BlinkMacSystemFont, "Segoe UI", system-ui, Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: source-code-pro, ui-monospace, Menlo, Monaco, Consolas, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-formal: 'Hedvig Letters Serif', serif;
    --font-family-handwriting: 'Nanum Pen Script', cursive;
    --font-family-strong: 'Roboto Slab', serif;

    /* Site */
    --font-size-medium: 1.2rem;
    --font-size-large: 2.5rem;
    --font-weight-bold: 700;
    --nav-height: 3.5rem;

    /* Theme Specific - (Light Theme Default) */
    --primary: #f8f9fa;
    --primary-contrast: #333; /* Assuming primary contrast color */
    --secondary: #b6a37d;
    --secondary-contrast: #202124; /* Assuming secondary contrast color */
    --accent: #ffff99;

    --background: #fff;
    --text: #333;
    --text-muted: #666; /* Assuming text-muted for quiet color */
    --text-bold: #000; /* Assuming text-bold for loud color */

    --nav: var(--primary);
    --nav-contrast: var(--secondary-contrast);

    --footer: var(--primary);
    --footer-contrast: var(--secondary-contrast);

    --widget: #fff;
    --widget-contrast: var(--text);

    --link: #0645ad;
    --link-hover: #036;
    --link-active: #5b80b2; /* Assuming link-active for selected links */
    --border: #ccc;
    --error: #ba2121;
    --success: #dfd;
    --warning: #ffc;
    --error-background: #ffefef; /* Assuming error-background for message error background */
    --darkened: #f8f8f8;
    --selected: #e4e4e4;
    --selected-row: #ffc;
    --button: var(--primary);
    --button-hover: #609ab6;
    --button-default: var(--secondary);
    --button-default-hover: #205067;
    --close-button: #747474;
    --close-button-hover: #333;
    --delete-button: #ba2121;
    --delete-button-hover: #a41515;
    --object-tools: var(--close-button);
    --object-tools-hover: var(--close-button-hover);

    /* Slider specific variables */

    --slider-value-color: #007bff;
    --slider-track-height: 1rem; /* Slider track height */
    --slider-track-color: #d3d3d3; /* Slider track color */
    --slider-track-border-color: #000;
    --slider-border-color: #000;

    --slider-thumb-height: 2rem; /* Slider thumb height */
    --slider-thumb-width: 1rem; /* Slider thumb width */
    --slider-thumb-border-radius: 5px;
    --slider-thumb-color: #fff; /* Slider thumb color */
    --slider-thumb-hover-color: #339bff; /* Slider thumb hover color */
    --slider-thumb-focus-outline-color: var(--slider-thumb-hover-color); /* Slider thumb focus outline color */
}


/* Normalize styles across browsers */
html {
  height: 100%;
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/* Global styles for body and #root */
body, #root {
    display: flex;
    flex-direction: column;
    margin: 0;
    min-height: 100vh; /* Set a minimum height for the body to ensure it takes at least the full viewport height */
    font-family: var(--font-family-primary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text);
    background-color: var(--background);
}

/* Styles for the content area */
.content {
  flex: 1;
  margin-top: var(--nav-height);
  display: flex;
  flex-direction: column;
}

.page-container {
  padding: 2rem; /* Add padding around the entire content */
  max-width: 800px; /* Or whatever max width you prefer */
  margin: 0 auto; /* Center the content */
  width: 100%;
}

/* Font styles */
.code {
  font-family: var(--font-family-monospace);
}
.formal {
  font-family: var(--font-family-formal);
}
.handwriting {
  font-family: var(--font-family-handwriting);
  font-size: var(--font-size-medium);
}
.serif-bold {
  font-family: var(--font-family-strong);
}

/* Global paragraph styles */
p {
  margin-bottom: 1rem;
}

.symbol-baseline-adjust{
  /*transform: translateY(11.5%);*/
  font-size: 1.2em; /* Adjust icon size as needed */
  vertical-align: middle; /* Aligns icons vertically in the middle */
}

/* Global button styles */
.button {
  padding: 10px 20px;
  border-radius: 3px;
  border: 1px solid transparent;
  /* Add other common button styles here */
}

/* Utility classes */
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add other flex properties as needed */
}

.text-center {
  text-align: center;
}

/* Responsive design */
@media (max-width: 768px) {
  /* Adjust styles for smaller screens */
  .button {
    padding: 8px 16px; /* Example adjustment */
  }
}