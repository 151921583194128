/* src/pages/Home.module.css */

.code {
  composes: code from '../index.css'; /* Assuming this is the correct path */
}

.home {
  padding: 2rem; /* Add padding around the entire content */
  max-width: 800px; /* Or whatever max width you prefer */
  margin: 0 auto; /* Center the content */
}

.title {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
}

.hero {
  margin-bottom: 2rem; /* Add some margin below the hero section */
  text-align: center;
}

.tagline {
    font-size: var(--font-size-medium);
}

.call-to-action{
    padding: 2rem;
}

.post-it {
    position: relative;
    max-width: 4in;
    min-height: 3.5in;
    margin: 3rem auto;
    padding: 2rem;
    background-color: var(--accent);
    color: black;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 5px; /* Optional: to give slightly rounded corners */
    text-align: justify;
    hyphens: auto;
    word-break: break-word;
}

.version-number {
  position: absolute;
  bottom: 10px; /* Adjust the value to fit your design */
  right: 15px; /* Adjust the value to fit your design */
  font-size: 12px; /* Small font size for a version number, adjust as needed */
  color: rgba(0, 0, 0, 0.7); /* Slightly faded black color for readability */
}


.taglineDefault {
    display: block; /* Show by default */
}

.taglineMedium, .taglineSmall {
    display: none; /* Hide by default */
}

@media (max-width: 810px) {
    .taglineDefault, .taglineSmall {
        display: none; /* Hide these */
    }
    .taglineMedium {
        display: block; /* Show this */
    }
}

@media (max-width: 455px) {
    .taglineDefault, .taglineMedium {
        display: none; /* Hide these */
    }
    .taglineSmall {
        display: block; /* Show this */
    }
}