/* OAuthLogOutButton.module.css */

.button-wrapper {
  margin-left: 1rem;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.button {
  background-color: transparent; /* Ensure background is transparent */
  border: none; /* Remove border */
  padding: 0;
  margin: 0;
  height: 24px; /* Define a fixed height for the button */
}

.button:hover, .button:focus {
  cursor: pointer;
  color: var(--link);
  font-variation-settings: 'wght' 600;
}

/* Add responsiveness to the button */
@media (max-width: 768px) {
  .button {
    /* Adjustments for smaller screens */
  }
}