/* TwoWaySlider.module.css */
.sliderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    position: relative;
}

.sliderLabels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    color: var(--widget-contrast);
}

.labelLeft, .labelRight {
    display: block;
    flex: 1;
    text-align: center;
    margin: 1rem .5rem;
}

/* Baseline, reset styles */
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    margin: 0;
    height: var(--slider-track-height);
    border-radius: var(--slider-track-height);
    border: 0;
}

/* Removes default focus */
input[type="range"]:focus {
    outline: none;
}



/* Slider Track - WebKit */
input[type="range"].slider::-webkit-slider-runnable-track {
    height: var(--slider-track-height);
    border-radius: var(--slider-track-height);
    border: 1px solid var(--slider-track-border-color, #ddd);
    box-shadow: none;
}
/* Slider Track - Mozilla */
input[type="range"].slider::-moz-range-track {
    height: var(--slider-track-height);
    border-radius: var(--slider-track-height);
    border: 1px solid var(--slider-track-border-color, #ddd);
    box-shadow: none;
}


/* Slider Thumb - WebKit */
input[type="range"].slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: calc((var(--slider-track-height) - var(--slider-thumb-height)) / 2); /* Centers thumb vertically */

    box-shadow: none;
    border: 1px solid var(--slider-border-color, #999);
    height: var(--slider-thumb-height);
    width: var(--slider-thumb-width);
    border-radius: var(--slider-thumb-border-radius);
    background: var(--slider-thumb-color, #ccc);
}
/* Slider Thumb - Mozilla */
input[type="range"].slider::-moz-range-thumb {
    border: 1px solid var(--slider-border-color, #999);
    border-radius: var(--slider-thumb-border-radius);
    box-shadow: none;
    height: var(--slider-thumb-height);
    width: var(--slider-thumb-width);
    background: var(--slider-thumb-color, #ccc);
}

/* Thumb Hover - WebKit */
input[type="range"].slider:hover::-webkit-slider-thumb {
    background: var(--slider-thumb-hover-color, #bbb);
    transform: scale(1);
}
/* Thumb Hover - Mozilla */
input[type="range"].slider:hover::-moz-range-thumb {
    background: var(--slider-thumb-hover-color, #bbb);
    transform: scale(1);
}


/* Thumb Focus - WebKit */
input[type="range"].slider:focus::-webkit-slider-thumb {
    transform: scale(1);
    outline: 3px solid var(--slider-thumb-focus-outline-color, #aaa);
    outline-offset: 0.125rem;
}
/* Thumb Focus - Mozilla */
input[type="range"].slider:focus::-moz-range-thumb {
    transform: scale(1);
    outline: 3px solid var(--slider-thumb-focus-outline-color, #aaa);
    outline-offset: 0.125rem;
}

/* ReadOnly Styles - WebKit */
.readOnlySlider::-webkit-slider-thumb {
    display: none;
}
/* ReadOnly Styles - Mozilla */
.readOnlySlider::-moz-range-thumb {
    display: none;
}

/* Extend readOnlySlider class for WebKit browsers */
.readOnlySlider, .readOnlySlider::-webkit-slider-runnable-track {
    cursor: default /* or 'not-allowed', as preferred */
}

/* Extend readOnlySlider class for Mozilla browsers */
.readOnlySlider::-moz-range-track {
    cursor: default /* or 'not-allowed', as preferred */
}


















/*.trackBackground {*/
/*    position: relative;*/
/*    width: 100%;*/
/*    height: var(--slider-track-height);*/
/*    border-radius: 0;*/
/*}*/

/*.gradientBackground {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    border-radius: var(--slider-track-height); !* Match the track's border-radius *!*/
/*}*/