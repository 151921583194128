/* navbar.module.css */

.header {
  width: 100%; /* Full width */
}

.nav-bar {
  position: fixed; /* Fixed position */
  top: 0; /* Stick to the top */
  left: 0; /* Align to the left side */
  width: 100%; /* Stretch across the full width */
  height: var(--nav-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--nav); /* Brand primary color */
  z-index: 1000; /* Ensure the navbar is above other content */
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.title-container {
  flex-shrink: 0; /* Prevents the title container from shrinking */
}

.title {
  color: var(--nav-contrast);
  margin: 0; /* Removes default margin */
  font-size: 1.25rem; /* Adjust font size if needed */
  white-space: nowrap; /* Prevents the title from wrapping */
  text-decoration: none;
  /* No font-family here; it will be inherited from the 'formal' class */
}

.button-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: flex-end; /* Aligns items to the right */
}

/* Add responsive design */
@media (max-width: 768px) {
  .nav-bar {
    flex-direction: row; /* Keep it as row */
    padding: 0.5rem 1rem; /* Reduce padding on smaller screens */
  }

  .title {
    font-size: 1.25rem; /* Smaller title for small screens */
  }
}
