/* src/pages/AdvisorProfile.module.css */

.advisor-container input[type="text"] {
  width: calc(100% - 20px); /* 100% width minus some padding */
  padding: 10px; /* Example padding for visual comfort */
  box-sizing: border-box; /* Ensures padding doesn't add to the width */
}

.title-bar {
  display: flex; /* Aligns items in a row */
  justify-content: space-between;
  align-items: center;
}

.button-group {
  display: flex;
  gap: 10px; /* Adjust the gap to your liking */
}

.save-btn, .cancel-btn {
  /* your existing button styles */
}

/* Additional styles for the edit button if it's not already defined */
.edit-btn {
  /*margin-left: auto; !* Pushes the button to the far right *!*/
  /*font-family: var(--font-family-monospace);*/
  /* Additional button styling here */
}


.school-link {
  display: inline-block; /* Makes the link only as wide as its content */
  white-space: nowrap; /* Prevents the link text itself from wrapping */
  flex-basis: auto;
  text-decoration: none; /* Optional: Removes underline from the link */
  color: inherit; /* Optional: Inherits the color from the parent */
  transition: color 0.3s ease; /* Smooth transition for color change */

  &:hover, &:focus {
    color: var(--link-hover); /* Example hover color */
    text-decoration: underline; /* Underline on hover for better UX */
  }
}

.school-link h3 {
  margin: 0; /* Removes default margin from h3 */
  display: inline; /* Optional: Makes h3 inline */
}

.school-name {
  /*font-family: ;*/
}



.advisor-container-inner {
  margin: 0 auto;
  width: 80%;
}

.advisor-school{
 /*font-size: 1.5em;*/
}

.advisor-name{
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: #eee;
}

td {
  vertical-align: top;
}

th.review-header {
  width: 50px;
}

.review-data {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  min-height: 100%;
  white-space: nowrap;
}

.add-review-button,
.view-reviews-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 24px;
  display: flex; /* Flex display to use justify and align */
  align-items: center; /* Center vertically */
  font-weight: bold;
}

.view-reviews {
  height: 24px;
  font-weight: bold;
}


/*
  justify-content: center;
  width: 100%;
*/

.add-review-button:hover, .add-review-button:focus {
  color: var(--link-active);
  font-variation-settings: 'FILL' 1;
}

.view-reviews-button:hover, .view-reviews-button:focus {
  color: var(--link-active);
}

.name-column {
  white-space: nowrap;
}

.research-area-column,
.department-column {
  max-width: 400px;
}

.link {
  color: var(--link);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

/* Media query for smaller screens */
@media (max-width: 700px) { /* Adjust breakpoint as needed */
  .department-column,
  .department-header {
    display: none; /* Hide these columns on smaller screens */
  }
}

@media (max-width: 600px) { /* Adjust breakpoint as needed */
  .research-area-column,
  .research-area-header {
    display: none; /* Hide these columns on smaller screens */
  }
}