/* SearchBar.module.css */

.search-container {
  margin-top: 1rem; /* Add space on top of search */
}

.search-form {
  position: relative; /* To position spinner or icons inside the form */
}

.search-input {
  width: 100%; /* Full width */
  padding: 10px 20px; /* Ample padding */
  font-size: 1rem; /* Readable text size */
  border: 1px solid #ddd; /* Subtle border */
  border-radius: 5px; /* Rounded corners */
}

.search-results {
  position: absolute; /* Overlay on top of the page content */
  background: white;
  width: 100%; /* Align with search input */
  max-height: 200px; /* Height for roughly 5 rows */
  overflow-y: auto; /* This will make the container scrollable */
  border: 1px solid #ddd;
  border-top: none; /* Seamless transition from input to results */
  border-radius: 0 0 5px 5px; /* Rounded corners on the bottom */
  box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* Soft shadow for depth */
  z-index: 1000; /* Ensure the results are above other content */
}

.results-list {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.result-item {
  padding: 10px; /* Padding for each result item */
  border-bottom: 1px solid #ddd; /* Separate each item */
}

.result-item:last-child {
  border-bottom: none; /* No border for the last item */
}

.result-link {
  text-decoration: none; /* Remove underline */
  color: var(--widget-contrast);
  display: block; /* Make the entire area clickable */
}

.result-link {
  text-decoration: none; /* Remove underline */
  color: var(--widget-contrast);
  display: block; /* Make the entire area clickable */
}

.result-link:hover {
  font-weight: bold;
}

.no-results {
  padding: 10px; /* Align padding with result items */
  text-align: center; /* Center the text */
}