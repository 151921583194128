/* SharedFormStyles.module.css */

.form-field-error {
  border-color: #721c24; /* Color from error-msg */
  background-color: #f8d7da; /* Lighter shade for field background */
}

.form-field-warning {
  border-color: #856404; /* Color from warning-msg */
  background-color: #fff3cd; /* Lighter shade for field background */
}

.error-message {
  color: var(--error);
  margin-top: 4px;
  font-size: 14px;
}

.warning-message {
  color: var(--warning);
  margin-top: 4px;
  font-size: 14px;
}

/* Add other common styles as needed */
