.container {
  /* width: 100vw;
  height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.asciiArt {
  font-family: 'Courier New', monospace;
  line-height: 1.2;
}