/* Buttons.module.css
shared button styles */
.button-base {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.button-base:hover {
  /* Use a CSS function to darken the button color, or specify a darker color */
  background-color: var(--button);
}

.button-icon {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}

@media (max-width: 768px) {
  .button-base {
    padding: 10px 8px;
    font-size: 14px;
  }

  .button-icon {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
}
