/* Container for all messages */
.message-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  max-width: 300px;
}

/* Individual message */
.message {
  background-color: #f4f4f4;
  padding: 10px 15px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* Close button */
.close-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #333;
  font-size: 16px;
}

/* Message types */
.info-msg {
  background-color: #d1ecf1;
  color: #0c5460;
  border-left: 4px solid #0c5460;
}

.success-msg {
  background-color: #d4edda;
  color: #155724;
  border-left: 4px solid #155724;
}

.warning-msg {
  background-color: #fff3cd;
  color: #856404;
  border-left: 4px solid #856404;
}

.error-msg {
  background-color: #f8d7da;
  color: #721c24;
  border-left: 4px solid #721c24;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .messages {
    bottom: 10px;
    right: 10px;
    max-width: 90%;
  }

  .close-btn {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .messages {
    bottom: 10px;
    right: 10px;
    max-width: 85%;
  }
}
